import React from "react";
import "./Logo.css";

const Logo = () => {
  return (
    <div className="logo">
      <h1>Dwelling Place Anaheim</h1>
      <p>Song Pool</p>
    </div>
  );
};

export default Logo;
