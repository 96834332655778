import React, { useRef } from "react";
import ReactDOM from "react-dom";

import "./SideDrawer.css";

const SideDrawer = (props) => {
  const nodeRef = useRef(null);

  const content = (
    <aside ref={nodeRef} className="side-drawer" onClick={props.onClick}>
      {props.children}
    </aside>
  );

  return ReactDOM.createPortal(content, document.getElementById("drawer-hook"));
};

export default SideDrawer;
