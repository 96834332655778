import React from "react";
import SongList from "../components/SongList";

const WorshipBank = () => {
  return (
    <section className="section-songs">
      <h1 className="center">Worship Song List</h1>
      <SongList />
    </section>
  );
};

export default WorshipBank;
