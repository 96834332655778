import React from "react";
import "./SongItem.css";
import { useState } from "react";
import Button from "../../UIElement/Button";
// import { getYoutube } from "../../util/youtubeThumbnail";

const SongItem = (props) => {
  const [viewVideo, setViewVideo] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const youtubeId = props.youtubeId;
  const time = props.time;
  const thumbnail = props.thumbnail;

  const copyLink = () => {
    const cb = navigator.clipboard;
    cb.writeText(props.link).then(() => {
      setLinkCopied(true);
      setTimeout(() => {
        setLinkCopied(false);
      }, 1000);
    });
  };

  const viewHandler = () => {
    setViewVideo((prev) => !prev);
  };
  return (
    <li key={props._id} className="song-item-box">
      <div className="song-list__item">
        <img
          className="thumbnail-img"
          alt="thumbnail for each video"
          src={thumbnail}
        />
        <h4 className="key-thumbnail">{props.songKey}</h4>
        <div className="song-list__content">
          <div className="item-header">
            <h2>{props.title}</h2>
            <h3>{props.ledBy}</h3>
            {props.ledBy2 && <h3>{props.ledBy2}</h3>}
          </div>
          <div className="link-box">
            <a href="#" onClick={copyLink}>
              <p>Copy Link</p>
              <span className={linkCopied ? "fade-in-effect" : ""}>
                Link copied!
              </span>
            </a>
          </div>
          <div className="button-box">
            <Button
              href={props.link}
              target="_blank"
              rel="noreferrer"
              onClick={viewHandler}
            >
              Open
            </Button>
          </div>
        </div>
      </div>
      {/* {viewVideo && (
        <div className="song-item-details">
          <iframe
            className="embeded-video"
            src={`https://www.youtube.com/embed/${youtubeId}?start=${time}`}
          ></iframe>
        </div>
      )} */}
    </li>
  );
};

export default SongItem;
