import React from "react";
import "./Button.css";

const Button = (props) => {
  return (
    <button className="style3" type={props.type}>
      <a href={props.href} target={props.target} onClick={props.onClick}>
        {props.children}
      </a>
    </button>
  );
};

export default Button;
