import React, { useState } from "react";

import "./MainNavigation.css";
import MainHeader from "./MainHeader";
import { Link } from "react-router-dom";
import NavLinks from "./NavLinks";
import SideDrawer from "./SideDrawer";
import Backdrop from "../UIElement/BackDrop";
import { gsap } from "gsap";

const MainNavigation = (props) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    const t1 = gsap.timeline({});
    const t2 = gsap.timeline({});
    t1.to(".whole-body", {
      x: "275px",
      ease: "ease-in",
      duration: 0.5,
    });
    t2.to(".side-drawer", {
      x: "0",
      ease: "ease-in",
      duration: 0.5,
    });
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
    const t1 = gsap.timeline({});
    const t2 = gsap.timeline({});
    t1.to(".whole-body", {
      x: "0px",
      ease: "ease-in",
      duration: 0.5,
    });
    t2.to(".side-drawer", {
      x: "-275px",
      ease: "ease-in",
      duration: 0.5,
    });
  };

  return (
    <>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}

      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav>
          <a className="link depth-0">
            <span className="indent-0"></span>
            Search
          </a>
          <a className="link depth-0">
            <span className="indent-0"></span>
            Worship Songs
          </a>
          <a className="link depth-1">
            <span className="indent-1"></span>
            All Songs
          </a>
          <a className="link depth-1">
            <span className="indent-1"></span>
            Worship Leaders
          </a>
          <a className="link depth-1">
            <span className="indent-1"></span>
            Songs by different artists
          </a>
          <a className="link depth-1">
            <span className="indent-1"></span>
            Songs from DPA
          </a>
          <a className="link depth-1">
            <span className="indent-1"></span>
            History
          </a>
        </nav>
      </SideDrawer>
      <div className="menu-btn-container" onClick={openDrawerHandler}>
        <a className="menu-btn"></a>
      </div>

      {/* <h1 className="main-navigation__title">
          <Link to="/">DPA Song Pool</Link>
        </h1> */}
      <nav className="nav">
        <NavLinks />
      </nav>
    </>
  );
};

export default MainNavigation;
