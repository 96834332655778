import React from "react";
import { NavLink } from "react-router-dom";
import "./NavLinks.css";

const NavLinks = (props) => {
  return (
    <ul className="nav-links">
      <li>
        <NavLink to="/" exact="true">
          Search
        </NavLink>
      </li>
      <li>
        <NavLink to="/worship-bank">Bank</NavLink>
      </li>
      {/* <li>
        <NavLink to="/u1/songs">My Songs</NavLink>
      </li>
      <li>
        <NavLink to="/users">Users</NavLink>
      </li> */}
      
    </ul>
  );
};

export default NavLinks;
