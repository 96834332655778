import React from "react";
import Logo from "./Logo";
import MainNavigation from "../Navigation/MainNavigation";
import './Header.css'

const Header = () => {
  return (
    <header className="header header-home">
      <Logo />
      <MainNavigation />
    </header>
  );
};

export default Header;
