import React from "react";
import SongItem from "./SongItem";
import "./SongList.css";



const SongList = (props) => {
  return (
    <>
      <ul className="song-list">
        {props.data &&
          props.data.map((song) => (
            <SongItem
              id={song._id}
              key={song._id}
              ledBy={song.nameLedBy}
              ledBy2={song.nameLedBy2}
              link={song.youtube}
              songKey={song.songKey}
              title={song.title}
              time={song.time}
              thumbnail={song.thumbnail}
              youtubeId={song.youtubeId}
            />
          ))}
      </ul>
    </>
  );
};

export default SongList;
