import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Main from "./Main/pages/Main";
import Login from "./Auth/pages/Login";
import SignUp from "./Auth/pages/SignUp";
import AddSong from "./Songs/pages/AddSong";
import EditSong from "./Songs/pages/EditSong";
import WorshipBank from "./Songs/pages/WorshipBank";
import UserSong from "./Songs/pages/UserSong";

import Users from "./Users/pages/Users";
import MainNavigation from "./Navigation/MainNavigation";
import Header from "./Header/Header";

const App = () => {
  return (
    <div className="whole-body">
      <Router>
        <Header />

        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/worship-bank" element={<WorshipBank />} />
          <Route exact path="/worship-bank/add-song" element={<AddSong />} />
          <Route exact path="/worship-bank/edit-song" element={<EditSong />} />
          {/* <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/:userId/songs" element={<UserSong />} />  */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
