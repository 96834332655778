import React, { useState } from "react";
import "./Main.css";
import SongList from "../../Songs/components/SongList";
import Button from '../../UIElement/Button'

const Main = () => {
  const [enteredTitle, setEnteredTitle] = useState("");
  const [enteredLeader, setEnteredLeader] = useState("");
  const [enteredKey, setEnteredKey] = useState("");
  const [searchData, setSearchData] = useState([]);

  const searchHandler = async (e) => {
    e.preventDefault();
    try {
      const queries = [];
      const keyQ = enteredKey && `key=${enteredKey}`;
      if (keyQ) {
        queries.push(keyQ);
      }
      const titleQ = enteredTitle && `title=${enteredTitle}`;
      if (titleQ) {
        queries.push(titleQ);
      }
      const leaderQ = enteredLeader && `leader=${enteredLeader}`;
      if (leaderQ) {
        queries.push(leaderQ);
      }
      const query = queries.join("&");

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/songs/search?${query}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = await response.json();
      setSearchData(responseData);
    } catch (err) {
      console.log(err);
    }
  };

  const titleChangeHandler = (e) => {
    setEnteredTitle(e.target.value);
  };

  const leaderChangeHandler = (e) => {
    setEnteredLeader(e.target.value);
  };

  const keyChangeHandler = (e) => {
    setEnteredKey(e.target.value);
  };
  return (
    <section className="wrapper style2">
      <div className="title">Search Songs</div>
      <div className="container">
        <div className="content">
          <article className="box">
            <header className="style1 search-header">
              <h2>DPA Song Search Engine</h2>
              <form className="search-form" onSubmit={searchHandler}>
                <div className="key-box">
                  <label htmlFor="songKey">Key:</label>
                  <select name="songKey" onChange={keyChangeHandler}>
                    <option value=""></option>
                    <option value="A">A</option>
                    <option value="Bb">A# / Bb</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="Db">C# / Db</option>
                    <option value="D">D</option>
                    <option value="Eb">D# / Eb</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="F#">F# / Gb</option>
                    <option value="G">G</option>
                    <option value="Ab">G# / Ab</option>
                    <option value="">(empty)</option>
                  </select>
                </div>
                <div className="search-box">
                  <input
                    onChange={titleChangeHandler}
                    type="text"
                    placeholder="search by title"
                    name="title"
                    label="title"
                  />
                  <input
                    onChange={leaderChangeHandler}
                    type="text"
                    placeholder="search by leader"
                    name="leader"
                    label="leader"
                  />
                </div>
                {/* <button type="submit">Search</button> */}
                <Button type="submit">Search</Button>
              </form>
            </header>
            <div className="search-results">
              <SongList data={searchData} />
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Main;
